<template>
  <el-dialog
    title="手动修改"
    :visible.sync="visible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" label-width="100px" label-suffix="：">
      <el-form-item label="开始日期">
        <el-date-picker
          v-model="form.start"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          style="width: 100%"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          v-model="form.end"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          type="date"
          style="width: 100%"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="文件">
        <el-upload
          style="width: 100%"
          class="fr dragarea"
          ref="uploadMutiple"
          
          action="/api/data/insertCCFileHour.json"
          :on-success="upLoadSuccess"
          :before-upload="beforeUpload"
          :data="{ ...form }"
          :headers="headers"
          drag
          :show-file-list="false"
          :multiple="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  changeChunChengData,
  getNowDateTime,
  getAfterDateTime,
} from "@/utils/masterdata";

const form = {
  area: null,
  type: "hour",
};

export default {
  data() {
    return {
      visible: false,
      form: {
      },
      startDate: "",
      userList: [],
      headers:{token:localStorage.getItem("bigdatatoken") || ""}
    };
  },
  mounted() {
  
    console.log(getNowDateTime());
    this.form.start = getNowDateTime();
    console.log(this.form.start);
  },
  methods: {
    getNowTime(e) {
      console.log(e);
    },
    beforeUpload() {
      if (!this.form.start) {
        this.$message.info("请输入开始日期");
        return false;
      }
      if (!this.form.end) {
        this.$message.info("请输入结束日期");
        return false;
      }
    },

    upLoadSuccess() {
      this.$message.success("上传成功");
      this.setVisible(false, null);
    },
    setVisible(visible, city) {
      this.visible = visible;
      this.form = { ...form };

      this.$set(this.form, "start", getNowDateTime());
      this.$set(this.form, "end", getAfterDateTime(getNowDateTime()));
      this.form.area = city;
    },
    handleClose() {
      this.visible = false;
      this.form = { ...form };
    },
    async addWarning() {
      let { body } = changeChunChengData(this.form);
      console.log(body);
    },
  },
};
</script>
<style>
.el-upload-dragger {
  width: 100%;
}
</style>
