<template>
  <el-card class="box-card mb10">
    <div class="tongyiyangshi">
      <h3>年度数据</h3>
      <div>
        <el-form ref="form" :model="form">
          <el-row>
            <el-col :span="6">
              <el-form-item label="旺庄总天数：">
                {{ form["旺庄"].count }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="旺庄优良天数：">
                {{ form["旺庄"].goodDays }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="旺庄PM2.5均值：">
                {{ form["旺庄"].pm25 }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="旺庄臭氧日均值：">
                {{ form["旺庄"].cy }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="春城总天数：">
                {{ form["春城"].count }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="春城优良天数：">
                {{ form["春城"].goodDays }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="春城PM2.5均值：">
                {{ form["春城"].pm25 }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="春城臭氧日均值：">
                {{ form["春城"].cy }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="tongyiyangshi">
      <el-radio-group v-model="datatype" size="small">
        <el-radio-button label="goodDaysMap">优良天数</el-radio-button>
        <el-radio-button label="pm25Map">PM2.5</el-radio-button>
        <el-radio-button label="cyMap">臭氧</el-radio-button>
      </el-radio-group>
      <div
        id="allyeardata"
        class="height"
        style="width: 100%; background: white"
      ></div>
    </div>
    <div style="display: flex; align-items: center">
      <el-radio-group v-model="city">
        <el-radio-button label="春城"></el-radio-button>
        <el-radio-button label="旺庄"></el-radio-button>
      </el-radio-group>

      <el-select
        @change="getData"
        style="margin-left: 20px"
        size="midden"
        v-model="selectYear"
        placeholder="请选择"
      >
        <el-option :label="item" :value="item" v-for="item in yearList"> </el-option>
      </el-select>
    </div>
    <div class="tongyiyangshi" style="height: 600px">
      <el-row v-if="city == '旺庄'">
        <el-col
          :span="6"
          v-for="(item, index) in monthDataList['旺庄']"
          :key="index"
          style="margin: 10px"
        >
          <div style="background: whitesmoke; width: 100%; padding: 10px">
            <h3>{{ item.dateTimeStr }}</h3>
            <div style="height: 35px; line-height: 35px; width: 100%">
              <div style="float: left; width: 50%">
                有效天数：{{ item.count }}
              </div>
              <div style="float: right; width: 50%">
                优良天数{{ item.goodDays }}
              </div>
            </div>
            <div style="height: 35px; line-height: 35px">
              PM2.5均值：{{ item.a34004 }}
            </div>
            <div style="height: 35px; line-height: 35px">
              臭氧均值：{{ item.a05024 }}
            </div>
            <div style="display: flex">
              <el-button
                size="small"
                style="margin-left: auto"
                @click="openUpdateDiaLog('旺庄')"
                >手动替换</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="city == '春城'">
        <el-col
          :span="6"
          v-for="(item, index) in monthDataList['春城']"
          :key="index"
          style="margin: 10px"
        >
          <div style="background: whitesmoke; width: 100%; padding: 10px">
            <h3>{{ item.dateTimeStr }}</h3>
            <div style="height: 35px; line-height: 35px; width: 100%">
              <div style="float: left; width: 50%">
                有效天数：{{ item.count }}
              </div>
              <div style="float: right; width: 50%">
                优良天数{{ item.goodDays }}
              </div>
            </div>
            <div style="height: 35px; line-height: 35px">
              PM2.5均值：{{ item.a34004 }}
            </div>
            <div style="height: 35px; line-height: 35px">
              臭氧均值：{{ item.a05024 }}
            </div>
            <div style="display: flex">
              <el-button
                size="small"
                style="margin-left: auto"
                @click="openUpdateDiaLog('春城')"
                >手动替换</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <upload ref="refupload"></upload>
  </el-card>
</template>

<script>
import upload from "@/components/alldate/upload";
import { getYearData, getMonthDataList } from "@/utils/masterdata.js";
export default {
  name: "index.vue",
  data() {
    return {
      form: {
        旺庄: {},
        春城: {},
      },
      city: "春城",
      axisLine: {
        lineStyle: {
          color: "#093E83",
        },
      },
      blueMax: [],
      axisLabel: {
        textStyle: {
          color: "white",
        },
      },
      yearList:[],
      chart: null,
      datatype: "goodDaysMap",
      selectPage: [
        { label: "pm2.5", value: "pm25", is_selected: true },
        { label: "pm10", value: "pm10", is_selected: false },
        { label: "氮氧化物", value: "NOx", is_selected: false },
        { label: "一氧化氮", value: "NO", is_selected: false },
        { label: "二氧化氮", value: "NO2", is_selected: false },
        { label: "二氧化硫", value: "SO2", is_selected: false },
        { label: "一氧化碳", value: "CO", is_selected: false },
      ],
      category: [11, 2, 3, 4, 5],
      min: 1,
      max: 30,
      color: {
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8",
            },
            {
              offset: 1,
              color: "#28f8de",
            },
          ],
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          //   背景渐变色
          colorStops: [
            {
              offset: 1,
              color: "rgba(0,0,255,0.1)",
            },
            {
              offset: 0.9,
              color: "rgba(0,51,204,0.2)",
            },
            {
              offset: 0.8,
              color: "rgba(0,153,255,0.3)",
            },
            {
              offset: 0.7,
              color: "rgba(51,153,255,0.4)",
            },
            {
              offset: 0.6,
              color: "rgba(153,204,255,0.5)",
            },
            {
              offset: 0.5,
              color: "rgba(255, 153, 102,0.6)",
            },
            {
              offset: 0.4,
              color: "rgba(255, 102, 102,0.7)",
            },
            {
              offset: 0.3,
              color: "rgba(255, 92, 51,0.8)",
            },
            {
              offset: 0.2,
              color: "rgba(255, 51, 0,0.9)",
            },
            {
              offset: 0,
              color: "rgba(255, 0, 0,1)",
            },
          ],
        },
      },
      colorList: [],
      cyMapList: [],
      chunChengCyMapList: [],
      chunChengCyMapDate: [],
      chunChengGoodDaysMapList: [],
      chunChengGoodDaysMapDate: [],
      chunChengPm25MapList: [],
      chunChengPm25MapDate: [],
      wangZhuangCyMapList: [],
      wangZhuangCyMapDate: [],
      wangZhuangGoodDaysMapList: [],
      wangZhuangGoodDaysMapDate: [],
      wangZhuangPm25MapList: [],
      wangZhuangPm25MapDate: [],
      monthDataList: [],
      selectYear: 2023,
    };
  },
  mounted() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear()
    let yearList = [currentYear]
    for (let i = 1; i <=3; i++) {
      yearList.push(currentYear-i)
      
    }
    this.yearList=yearList
    this.selectYear=currentYear
    this.getYearData();
    this.getMonthDataList();

  },
  components: {
    upload,
  },
  watch: {
    datatype(newValue, oldValue) {
      if (newValue === "goodDaysMap") {
        this.blueMax = this.chunChengGoodDaysMapList;
        this.wzAvg = this.wangZhuangGoodDaysMapList;
        this.category = this.chunChengCyMapDate;
        return this.draw();
      }
      if (newValue == "pm25Map") {
        this.blueMax = this.chunChengPm25MapList;
        this.wzAvg = this.wangZhuangPm25MapList;
        this.category = this.chunChengPm25MapDate;
        return this.draw();
      }
      if (newValue == "cyMap") {
        this.blueMax = this.chunChengCyMapList;
        this.wzAvg = this.wangZhuangCyMapList;
        this.category = this.wangZhuangCyMapDate;
        this.draw();
      }
    },
  },
  methods: {
    getData() {
      this.getYearData();
      this.getMonthDataList();
    },
    openUpdateDiaLog(city) {
      console.log(city);
      this.$refs.refupload.setVisible(true, city);
    },
    async getMonthDataList() {
      let { body } = await getMonthDataList(this.selectYear);
      console.log(body);
      this.monthDataList = body;
    },
    async getYearData() {
      // alert(this.selectYear);

      let { body } = await getYearData(this.selectYear);
      this.form = body;
      console.log(body);
      this.getChunChengDataOrDate(body);
      this.getWangZhuangDataOrDate(body);
      this.blueMax = this.chunChengGoodDaysMapList;
      this.wzAvg = this.wangZhuangGoodDaysMapList;
      this.category = this.chunChengCyMapDate;
      this.draw();
    },

    draw() {
      this.chart = this.$echarts.init(document.getElementById("allyeardata"));
      function fontSize(res) {
        let clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      }

      let category = this.category;
      let color = this.color;
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            show: true,
          },
        },

        legend: {
          show: true,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 16,
          bottom: 10,
          textStyle: {
            color: "#A3E2F4",
            fontSize: 12,
            fontWeight: 0,
          },
          data: ["春城", "旺庄"],
        },
        grid: {
          left: "3%",
          top: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: true,
          data: category,
          axisLabel: {
            interval: 0,
            formatter: "{value}",
            show: true,
            padding: [0, 0, 20, 0],
            color: "#00c7ff",
            fontSize: 10,
          },
          //   竖向背景轴颜色
          axisLine: this.axisLine,
        },
        yAxis: {
          type: "value",
          // 背景坐标轴颜色配置
          axisLine: this.axisLine,

          formatter: "123",
          //   横向背景轴颜色
          axisLabel: this.axisLabel,
          splitLine: this.axisLine,
        },

        series: [
          {
            name: "春城",
            type: "line",
            data: this.blueMax,
            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            itemStyle: {
              normal: {
                color: color.linearBtoG,
              },
            },
            markLine: {
              symbol: false,
              data: [{ type: "average", yAxis: this.line, name: "平均值" }],
              itemStyle: {
                normal: {
                  color: "#ff9933",
                },
              },
              lineStyle: {
                normal: {
                  opacity: this.line == null ? 0 : 1,
                },
              },
              label: {
                normal: {
                  fontSize: fontSize(0.14),
                  formatter: this.line == null ? "" : "优\n良\n阈\n值",
                },
              },
            },
          },
          {
            name: "旺庄",
            type: "line",
            data: this.wzAvg,
            label: {
              show: false,
              color: "white",
              formatter: function (data) {
                return data.value;
              },
            },
            // itemStyle: {
            //   normal: {
            //     color: color.linearBtoGB,
            //   },
            // },
          },
        ],
      };

      this.chart.setOption(option);
    },
    getChunChengDataOrDate(body) {
      let chunChengCyMapList = [];
      let chunChengCyMapDate = [];
      let chunChengGoodDaysMapList = [];
      let chunChengGoodDaysMapDate = [];
      let chunChengPm25MapList = [];
      let chunChengPm25MapDate = [];

      //获取春城臭氧的时间和数据
      for (const i in body["春城"].cyMap) {
        chunChengCyMapList.push(body["春城"].cyMap[i]);
        chunChengCyMapDate.push(i);
      }
      //获取春城优良天数的时间和数据
      for (const i in body["春城"].goodDaysMap) {
        chunChengGoodDaysMapList.push(body["春城"].goodDaysMap[i]);
        chunChengGoodDaysMapDate.push(i);
      }
      //获取春城pm2.5的时间和数据
      for (const i in body["春城"].pm25Map) {
        chunChengPm25MapList.push(body["春城"].pm25Map[i]);
        chunChengPm25MapDate.push(i);
      }
      this.chunChengCyMapList = chunChengCyMapList;
      this.chunChengCyMapDate = chunChengCyMapDate;
      this.chunChengGoodDaysMapList = chunChengGoodDaysMapList;
      this.chunChengGoodDaysMapDate = chunChengGoodDaysMapDate;
      this.chunChengPm25MapList = chunChengPm25MapList;
      this.chunChengPm25MapDate = chunChengPm25MapDate;
    },
    getWangZhuangDataOrDate(body) {
      let wangZhuangCyMapList = [];
      let wangZhuangCyMapDate = [];
      let wangZhuangGoodDaysMapList = [];
      let wangZhuangGoodDaysMapDate = [];
      let wangZhuangPm25MapList = [];
      let wangZhuangPm25MapDate = [];
      //获取旺庄臭氧的时间和数据
      for (const i in body["旺庄"].cyMap) {
        wangZhuangCyMapList.push(body["旺庄"].cyMap[i]);
        wangZhuangCyMapDate.push(i);
      }
      //获取旺庄优良天数的时间和数据
      for (const i in body["旺庄"].goodDaysMap) {
        wangZhuangGoodDaysMapList.push(body["旺庄"].goodDaysMap[i]);
        wangZhuangGoodDaysMapDate.push(i);
      }
      //获取旺庄pm2.5的时间和数据
      for (const i in body["旺庄"].pm25Map) {
        wangZhuangPm25MapList.push(body["旺庄"].pm25Map[i]);
        wangZhuangPm25MapDate.push(i);
        this.wangZhuangCyMapList = wangZhuangCyMapList;
        this.wangZhuangCyMapDate = wangZhuangCyMapDate;
        this.wangZhuangGoodDaysMapList = wangZhuangGoodDaysMapList;
        this.wangZhuangGoodDaysMapDate = wangZhuangGoodDaysMapDate;
        this.wangZhuangPm25MapList = wangZhuangPm25MapList;
        this.wangZhuangPm25MapDate = wangZhuangPm25MapDate;
      }
    },
  },
};
</script>

<style scoped>
.el-col-6 {
  margin-bottom: -20px;
}
.tongyiyangshi {
  padding: 10px;
  background: white;
}
.height {
  height: 400px;
}
</style>
